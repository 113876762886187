import LocalizedStrings from "react-localization";

export const mappingKeysStrings = new LocalizedStrings({
  en: {
    title: "Mapping keys",
    advertiser: "Advertiser",
    campaign: "Campaign",
    domain: "Domain",
    product: "Product",
    mappingStatus: "Status",
    date: "Date",
    exportButton: "Export",
    resetFiltersButton: "Reset",
    errors: {
      FieldRequired: "There are no campaigns selected for locking.",
      LinesMissingOrLocked: "The campaign you selected is locked or not found.",
      LockingNotAllowed: "There was an error, while locking the lines.",
      UnlockingNotAllowed: "There was an error, while unlocking the lines.",
      TotalResultsExceedsMaxAllowed:
        "There are more than 10 000 results. Please refine your search.",
    },
  },
  fr: {
    title: "Clés de Mapping",
    advertiser: "Annonceur",
    campaign: "Campagne",
    domain: "Domaine",
    product: "Produit",
    mappingStatus: "Statut",
    date: "Date",
    exportButton: "Exporter",
    resetFiltersButton: "Réinitialiser",
    errors: {
      FieldRequired: "Aucun Campagne n'a été sélectionné pour le verrouillage.",
      LinesMissingOrLocked:
        "Le Campagne que vous avez sélectionné est verrouillé ou introuvable.",
      LockingNotAllowed:
        "Une erreur s'est produite lors du verrouillage des lignes.",
      UnlockingNotAllowed:
        "Une erreur s'est produite lors du déverrouillage des lignes.",
      TotalResultsExceedsMaxAllowed:
        "Il y a plus de 10 000 résultats. Veuillez affiner votre recherche.",
    },
  },
});
