import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import {
  API_ROUTE_MAPPING_KEYS,
  API_ROUTE_MAPPING_KEYS_EXPORT,
  API_ROUTE_MAPPING_LOCKED_LINES,
} from "../../routes";
import { debug } from "../../utils/helpers";
import {
  DEFAULT_MAPPING_KEYS_PAYLOAD,
  DEFAULT_MAPPING_KEYS_EXPORT_PAYLOAD,
} from "../../constants";

/*
  The auto parameter, when set to false, allows manual control over when the getMappingKeys function is invoked. 
  If auto is true, the function is automatically called during the initial render, but when set to false, 
  it provides the flexibility for the user to trigger the function manually based on specific conditions or events.
*/
export default function useMappingKeys(auto = false) {
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [mappingKeys, setMappingKeys] = useState([]);

  const getMappingKeys = (payload = {}, showLoader = true) => {
    setIsLoading(showLoader);

    Api.post(API_ROUTE_MAPPING_KEYS, {
      ...DEFAULT_MAPPING_KEYS_PAYLOAD,
      ...payload,
    })
      .then((response) => {
        setMappingKeys(response.data);
      })
      .catch((error) => {
        debug.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportMappingKeys = (payload = {}) => {
    setIsExporting(true);

    return Api.post(
      API_ROUTE_MAPPING_KEYS_EXPORT,
      {
        ...DEFAULT_MAPPING_KEYS_EXPORT_PAYLOAD,
        ...payload,
      },
      { responseType: "blob" },
    )
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch(async (error) => {
        debug.error(error);
        const err = JSON.parse(await error?.response?.data.text());
        return Promise.reject(err?.errors?.SearchFilter);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  useEffect(() => {
    if (!auto) {
      return;
    }
    getMappingKeys();
  }, [auto]);

  const lockMappingLines = (payload, showLoader = false) => {
    setIsLoading(showLoader);

    return Api.patch(API_ROUTE_MAPPING_LOCKED_LINES, payload)
      .catch((error) => Promise.reject(error?.response?.data?.errors?.LineIds))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    isExporting,
    mappingKeys,
    getMappingKeys,
    exportMappingKeys,
    lockMappingLines,
  };
}
