import { useContext } from "react";
import PropTypes from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import {
  PersonSearchOutlined as PersonSearchIcon,
  HourglassBottomOutlined as HourglassBottomIcon,
  LayersOutlined as LayersIcon,
  VisibilityOutlined as VisibilityIcon,
  SettingsOutlined as SettingsIcon,
  Logout as LogoutIcon,
  Close as CloseIcon,
  PermMedia as PermMediaIcon,
} from "@mui/icons-material";
import { AppContext } from "../../../pages/root/Root";
import { initLocalization } from "../../../utils/helpers";
import {
  ROUTE_ADMIN,
  ROUTE_LOGIN,
  ROUTE_MAIN,
  ROUTE_PATHMATICS_LEVEL2,
  ROUTE_PATHMATICS_LEVEL3,
  ROUTE_BIS_LEVEL1,
  ROUTE_BIS_LEVEL2,
  ROUTE_BIS_LEVEL3,
  ROUTE_MAPPINGS_VIEW,
  API_ROUTE_LOG_OUT,
  API_ROUTE_MAPPING_UNLOCK_ALL_LINES,
} from "../../../routes";
import Api from "../../../utils/Api";
import { burgerMenuStrings } from "./locale";
import "./style.scss";

export const BurgerMenu = ({ open, onClose }) => {
  initLocalization(burgerMenuStrings);

  const navigate = useNavigate();
  const { isAdmin } = useContext(AppContext);

  const handleLogout = async () => {
    await Api.patch(API_ROUTE_MAPPING_UNLOCK_ALL_LINES);
    await Api.post(API_ROUTE_LOG_OUT);
    navigate(ROUTE_LOGIN, { replace: true });
  };

  return (
    <Drawer
      className="burger-menu"
      open={open}
      elevation={3}
      hideBackdrop={true}
      onClose={onClose}
      data-testid="burger-menu-list"
      disableEnforceFocus
    >
      <List
        subheader={
          <ListSubheader className="burger-menu__header" sx={{ p: 1 }}>
            <IconButton
              onClick={onClose}
              aria-label={burgerMenuStrings.closeMenu}
              data-testid="close-burger-menu"
            >
              <CloseIcon color="primary" sx={{ width: 20, height: 20 }} />
            </IconButton>
          </ListSubheader>
        }
      >
        <ListItem>
          <Grid
            display="flex"
            flexDirection="row"
            gap={0.5}
            paddingX={2}
            paddingTop={1}
            fontWeight="bold"
          >
            <ListItemIcon>
              <PersonSearchIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              sx={{
                "& p": {
                  fontWeight: "bold",
                  fontSize: "1rem",
                },
              }}
            >
              {burgerMenuStrings.paidSocialCategory}
            </ListItemText>
          </Grid>
        </ListItem>
        <ListItem>
          <ListItemButton
            component={NavLink}
            to={ROUTE_MAIN}
            data-testid="pathmatics-level1-link"
          >
            <ListItemIcon>
              <LayersIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>{burgerMenuStrings.mappingLevel}</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            component={NavLink}
            to={ROUTE_PATHMATICS_LEVEL2}
            data-testid="pathmatics-level2-link"
          >
            <ListItemIcon>
              <LayersIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>
              {burgerMenuStrings.creativeClassification}
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            component={NavLink}
            to={ROUTE_PATHMATICS_LEVEL3}
            data-testid="pathmatics-level3-link"
          >
            <ListItemIcon>
              <VisibilityIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>{burgerMenuStrings.creativeReview}</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Grid
            display="flex"
            flexDirection="row"
            gap={0.5}
            paddingX={2}
            paddingTop={1}
            fontWeight="bold"
          >
            <ListItemIcon>
              <HourglassBottomIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              sx={{
                "& p": {
                  fontWeight: "bold",
                  fontSize: "1rem",
                },
              }}
            >
              {burgerMenuStrings.displayCategory}
            </ListItemText>
          </Grid>
        </ListItem>
        <ListItem>
          <ListItemButton
            component={NavLink}
            to={ROUTE_BIS_LEVEL1}
            data-testid="bis-level1-link"
          >
            <ListItemIcon>
              <LayersIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>{burgerMenuStrings.mappingLevel}</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            component={NavLink}
            to={ROUTE_BIS_LEVEL2}
            data-testid="bis-level2-link"
          >
            <ListItemIcon>
              <LayersIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>
              {burgerMenuStrings.creativeClassification}
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            component={NavLink}
            to={ROUTE_BIS_LEVEL3}
            data-testid="bis-level3-link"
          >
            <ListItemIcon>
              <VisibilityIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>{burgerMenuStrings.creativeReview}</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Grid
            display="flex"
            flexDirection="row"
            gap={0.5}
            paddingX={2}
            paddingTop={1}
            fontWeight="bold"
          >
            <ListItemIcon>
              <PermMediaIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              sx={{
                "& p": {
                  fontWeight: "bold",
                  fontSize: "1rem",
                },
              }}
            >
              {burgerMenuStrings.selfPromotionCategory}
            </ListItemText>
          </Grid>
        </ListItem>
        <ListItem>
          <ListItemButton
            component={NavLink}
            to={ROUTE_MAPPINGS_VIEW}
            data-testid="media-link"
          >
            <ListItemIcon>
              <LayersIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>{burgerMenuStrings.mappingsView}</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {isAdmin && (
          <ListItem>
            <ListItemButton
              component={NavLink}
              to={ROUTE_ADMIN}
              data-testid="admin-page-link"
            >
              <ListItemIcon>
                <SettingsIcon color="secondary" />
              </ListItemIcon>
              <ListItemText>{burgerMenuStrings.admin}</ListItemText>
            </ListItemButton>
          </ListItem>
        )}
        <ListItem>
          <ListItemButton onClick={handleLogout} data-testid="log-out-link">
            <ListItemIcon>
              <LogoutIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>{burgerMenuStrings.logout}</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

BurgerMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
