import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../../pages/root/Root";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import { Refresh, Download } from "@mui/icons-material";

import { AppAlert } from "../../common/appAlert/AppAlert";
import { DataTable } from "../../common/dataTable/DataTable";
import { MappingKeysFilters } from "../mappingKeysFilters/MappingKeysFilters";
import { MappingStatusColored } from "../../common/mappingStatusColored/MappingStatusColored";

import useMappingKeys from "../../../hooks/bis/useMappingKeys";
import useIsMounted from "../../../hooks/common/useIsMounted";
import useNotificationHub from "../../../hooks/common/useNotificationHub";
import { MappingKeysTimer } from "../../common/mappingKeysTimer/MappingKeysTimer";

import { downloadFile, initLocalization } from "../../../utils/helpers";
import { mappingKeysStrings } from "./locale";
import {
  MAPPING_KEYS_DATE_FORMAT,
  DEFAULT_TABLE_PAGINATION,
  DEFAULT_BIS_MAPPING_KEYS_FILTERS,
  DEFAULT_BIS_MAPPING_KEYS_SORTING,
} from "../../../constants";

export const MappingKeys = ({
  shouldRefresh,
  onMappingKeysSelection,
  onDisableAllButtons,
}) => {
  initLocalization(mappingKeysStrings);

  const isMounted = useIsMounted();

  const { user, language } = useContext(AppContext);

  const lastSelected = useRef(null);
  const [error, setError] = useState("");
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [pagination, setPagination] = useState(DEFAULT_TABLE_PAGINATION);
  const [filters, setFilters] = useState(DEFAULT_BIS_MAPPING_KEYS_FILTERS);
  const [sorting, setSorting] = useState(DEFAULT_BIS_MAPPING_KEYS_SORTING);
  const [scheduleTimer, setScheduleTimer] = useState(false);
  const [filtersVersion, setFiltersVersion] = useState(1);

  const {
    isLoading: mappingKeysLoading,
    isExporting,
    mappingKeys,
    getMappingKeys,
    setMappingKeys,
    exportMappingKeys,
    lockMappingLines,
  } = useMappingKeys();
  const { message: updatedMappingKeys } = useNotificationHub();

  const mappingKeysData = useMemo(() => {
    if (updatedMappingKeys?.lineIds?.length) {
      const shouldLock = updatedMappingKeys.status === "Lock";
      const isCurrentUser = updatedMappingKeys.userId === user.id;

      return mappingKeys?.data?.map((row) => {
        // Prevent unlock visual flash when multiple selecting
        if (!shouldLock && selectedRows.has(row.id)) {
          return row;
        }

        if (updatedMappingKeys.lineIds.includes(row.id)) {
          row.isLocked = shouldLock;
          row.isLockedByCurrentUser = isCurrentUser && shouldLock;
        }

        return row;
      });
    }

    return mappingKeys?.data;
  }, [mappingKeys, updatedMappingKeys]);

  const columns = [
    {
      field: "advertiser",
      headerName: mappingKeysStrings.advertiser,
      minWidth: 100,
    },
    {
      field: "campaign",
      headerName: mappingKeysStrings.campaign,
      minWidth: 120,
    },
    {
      field: "domain",
      headerName: mappingKeysStrings.domain,
      minWidth: 100,
    },
    {
      field: "productDetails",
      headerName: mappingKeysStrings.product,
      minWidth: 120,
      format: (value) => (value !== null ? value.name : ""),
    },
    {
      field: "status",
      headerName: mappingKeysStrings.mappingStatus,
      minWidth: 62,
      format: (value, row) => <MappingStatusColored value={value} row={row} />,
    },
    {
      field: "firstOccurrence",
      headerName: mappingKeysStrings.date,
      minWidth: 45,
      format: (value) => dayjs(value).format(MAPPING_KEYS_DATE_FORMAT),
    },
  ];

  const filtersChanged = () =>
    JSON.stringify(DEFAULT_BIS_MAPPING_KEYS_FILTERS) !==
    JSON.stringify(filters);

  const getSelected = (currentIndex, allowMultiple, indices) => {
    const prevArray = [...selectedRows];
    const current = prevArray.find(
      (id) => id === mappingKeysData[currentIndex].id,
    );
    const currentSet =
      current && prevArray.length === 1 ? new Set([current]) : new Set();
    const next = allowMultiple ? new Set(selectedRows) : currentSet;

    indices.forEach((idx) => {
      const id = mappingKeysData[idx].id;
      next.has(id) ? next.delete(id) : next.add(id);
    });

    return next;
  };

  // Select row function
  const handleSelectRow = (event, index) => {
    if (
      mappingKeysData[index].isLocked &&
      !mappingKeysData[index].isLockedByCurrentUser
    ) {
      return;
    }

    const indices = new Set();
    let allowMultiple = false;

    indices.add(index);

    if (event.shiftKey) {
      const limits = [lastSelected.current ?? 0, index].sort((a, b) => a - b);
      const [start, end] = limits;

      for (let i = start; i <= end; i++) {
        if (
          mappingKeysData[i].isLocked &&
          !mappingKeysData[i].isLockedByCurrentUser
        ) {
          continue;
        }
        indices.add(i);
      }

      indices.delete(lastSelected.current);
      allowMultiple = true;
    } else if (event.metaKey || event.ctrlKey) {
      allowMultiple = true;
    }

    const newSelection = getSelected(index, allowMultiple, indices);

    setSelectedRows(newSelection);
    onMappingKeysSelection(
      [...mappingKeysData].filter((entry) => newSelection.has(entry.id)),
    );

    lastSelected.current = index;
  };

  const lockLines = async (selection) => {
    try {
      setScheduleTimer(false);
      // Reset locked lines. Stop execution if unlocking fails
      await lockMappingLines({
        shouldLock: false,
      }).catch((error) => {
        throw new Error(error[0]);
      });

      if (selection.length) {
        await lockMappingLines({
          shouldLock: true,
          lineIds: selection,
        })
          .then(() => setScheduleTimer(true))
          .catch((error) => {
            setError(error[0]);
            console.log(error[0]);
          });
      }
    } catch (err) {
      setError(err.message);
      console.log(err.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, pageNumber: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    setPagination({ ...pagination, pageNumber: 0, pageSize: value });
  };

  const handleSort = (columnId, direction) => {
    const sortColumn = columnId.charAt(0).toUpperCase() + columnId.slice(1);
    setPagination({ ...pagination, pageNumber: 0 });
    setSorting({ ...sorting, sortOption: { sortColumn, direction } });
  };

  const handleExport = async () => {
    exportMappingKeys({
      ...filters,
      ...sorting,
      userLanguage: language,
    })
      .then((data) => {
        downloadFile(
          `bis-${mappingKeysStrings.title.replaceAll(" ", "-").toLowerCase()}`,
          "xlsx",
          data,
        );
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleResetFilters = () => {
    setFilters(DEFAULT_BIS_MAPPING_KEYS_FILTERS);
    setFiltersVersion((prev) => prev + 1);
  };

  const handleCloseError = () => setError(false);

  const updateMappingKeys = () => {
    getMappingKeys({
      ...pagination,
      pageNumber: pagination.pageNumber + 1,
      ...filters,
      ...sorting,
    });

    onDisableAllButtons(true);
  };

  const mappingTableChange = async () => {
    await lockMappingLines(
      {
        shouldLock: false,
      },
      true,
    );

    setSelectedRows(() => new Set());
    onMappingKeysSelection([]);
    lastSelected.current = null;

    updateMappingKeys();
  };

  useEffect(() => {
    if (lastSelected.current === null) {
      return;
    }

    lockLines([...selectedRows]);
    onDisableAllButtons(false);
  }, [selectedRows]);

  useEffect(() => {
    if (shouldRefresh) {
      mappingTableChange();
      setScheduleTimer(false);
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (isMounted) {
      setScheduleTimer(false);
      if (filtersChanged()) {
        mappingTableChange();
      } else {
        setMappingKeys([]);
      }
    }
  }, [pagination, filters, sorting]);

  return (
    <Paper sx={{ padding: "0.5rem", height: "100%", position: "relative" }}>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {mappingKeysStrings.errors[error]}
      </AppAlert>
      <Grid container direction="column" height="100%">
        <Grid xs={12} container justifyContent="space-between" minHeight="32px">
          <Typography>{mappingKeysStrings.title}</Typography>
          <Grid container>
            <MappingKeysTimer
              scheduleTimer={scheduleTimer}
              setSelectedRowsCB={() => setSelectedRows(() => new Set())}
            />
            <Button
              startIcon={
                isExporting ? (
                  <CircularProgress size={18} sx={{ opacity: 0.5 }} />
                ) : (
                  <Download />
                )
              }
              variant="outlined"
              disabled={!mappingKeysData?.length || isExporting}
              onClick={handleExport}
              sx={{ ml: 2 }}
            >
              {mappingKeysStrings.exportButton}
            </Button>
            <Button
              startIcon={<Refresh sx={{ transform: "scaleX(-1)" }} />}
              variant="text"
              onClick={handleResetFilters}
              sx={{ ml: 2 }}
            >
              {mappingKeysStrings.resetFiltersButton}
            </Button>
          </Grid>
        </Grid>

        <Grid xs={12} paddingY={3}>
          <MappingKeysFilters
            key={filtersVersion}
            setPagination={setPagination}
            setFilters={setFilters}
            filters={filters}
            columns={columns}
          />
        </Grid>

        <Grid xs={12} display="flex" flexDirection="column" flexGrow="1">
          <DataTable
            columns={columns}
            rows={mappingKeysData || []}
            selectedRows={[...selectedRows]}
            page={pagination.pageNumber}
            rowsPerPage={pagination.pageSize}
            totalRecords={mappingKeys.totalRecords}
            sortBy={
              sorting.sortOption.sortColumn.charAt(0).toLowerCase() +
              sorting.sortOption.sortColumn.slice(1)
            }
            sortDirection={sorting.sortOption.direction}
            sortableColumns={[
              "advertiser",
              "campaign",
              "domain",
              "productDetails",
              "firstOccurrence",
            ]}
            isLockedKeys={["isLocked"]}
            isLockedByUserKey="isLockedByCurrentUser"
            onSelectRow={handleSelectRow}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSort}
            showNoDataMessage={false}
            tableMinWidth={1079}
          />
        </Grid>
      </Grid>

      <Backdrop
        open={mappingKeysLoading}
        sx={{
          position: "absolute",
          color: "#fff",
          borderRadius: 1,
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

MappingKeys.propTypes = {
  shouldRefresh: PropTypes.number,
  onMappingKeysSelection: PropTypes.func.isRequired,
  onDisableAllButtons: PropTypes.func.isRequired,
};
