import LocalizedStrings from "react-localization";

export const mappingKeysFiltersStrings = new LocalizedStrings({
  en: {
    mappingStatus: "Status",
    auto: "Auto",
    manual: "Manual",
    reject: "Reject",
    notMapped: "Not Mapped",
    manualDomainMapped: "Manual Domain Mapped",
    autoDomainMapped: "Auto Domain Mapped",
    productCreated: "Product Created",
    productRequested: "Product Requested",
    all: "All",
    searchText: "Search (text)",
    searchNumber: "Search (number)",
    searchBy: "By",
    startDate: "Start date",
    endDate: "End date",
    productId: "Product ID",
  },
  fr: {
    mappingStatus: "Statut",
    auto: "Auto",
    manual: "Manual",
    reject: "Rejeté",
    notMapped: "A Faire",
    manualDomainMapped: "Manual Domain Mapped",
    autoDomainMapped: "Auto Domain Mapped",
    productCreated: "T. à valider",
    productRequested: "T. en cours",
    all: "Tout",
    searchText: "Recherche (texte)",
    searchNumber: "Recherche (numéro)",
    searchBy: "Par",
    startDate: "Date de début",
    endDate: "Date de fin",
    productId: "ID Produit",
  },
});
