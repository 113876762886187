import LocalizedStrings from "react-localization";

export const mappingStatusesStrings = new LocalizedStrings({
  en: {
    label: "Status",
    all: "All",
    "Not mapped": "Not Mapped",
    Validated: "Validated",
    Rejected: "Rejected",
    "Waiting, product requested": "P. requested",
    "Waiting, product created": "P. created",
  },
  fr: {
    label: "Statut",
    all: "Tout",
    "Not mapped": "A Faire",
    Validated: "Validé",
    Rejected: "Rejeté",
    "Waiting, product requested": "T. en cours",
    "Waiting, product created": "T. à valider",
  },
});
