import LocalizedStrings from "react-localization";

export const mappingCreationStrings = new LocalizedStrings({
  en: {
    resetFiltersButton: "Reset",
    addMappings: "Add Mappings",
  },
  fr: {
    resetFiltersButton: "Réinitialiser",
    addMappings: "Add Mappings",
  },
});
